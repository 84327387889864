<template>
    <div>
        <h3>{{ $t('ordonnance.multi_ordonnances') }}</h3>

		<label>{{ $t('ordonnance.donner_un_nom') }}* :</label>
        <div class="form-group">
            <input type="text" class="form-control" v-model="document_name" :placeholder="$t('ordonnance.donner_un_nom')" required>
        </div>
		
        <label>{{ $t('ordonnance.documents_dates') }}* :</label>
        <div class="form-group">
            <e-datepicker v-model="document_date_multiple" required />
        </div>
        <label>{{ $t('fichier.fichiers') }}* :</label>
        <b-form-file
            v-model="document_file_multiple"
            :multiple="true"
            :accept="ordonnance_type_file_accepted"
            :state="Boolean(document_file_multiple) && isValidSizeFileMultiple"
            :placeholder="$t('ordonnance.choisir_documents')"
            :drop-placeholder="$t('fichier.drop_files')+'...'"
            ref="fileFormMultiple"
        ></b-form-file>
        <div v-show="document_file_multiple && isValidSizeFileMultiple" class="mt-3">
            {{ $t('fichier.selected_files') }} :
            <span v-for="document in document_file_multiple" :key="document.name">
                {{ document ? document.name : '' }}
            </span>
        </div>
        <span v-if="document_file_multiple && !isValidSizeFileMultiple">
            <br>
            <ErrorFileSize messageI18n="fichier.fichier_trop_lourd" />
        </span>

        <ButtonValidation v-if="canGoNextMultiple" @btn-onclick="valid_form_multiple" icon="suivant" />
    </div>
</template>

<script type="text/javascript">
    import Shutter from '@/mixins/Shutter.js'
    import Documents from "@/mixins/Documents.js"

	export default {
		name: "OrdonnanceForm",
		mixins: [Documents, Shutter],
        props: [
            'horses_ids',
            'from_mediatheque'
        ],
        components: {
            ButtonValidation: () => import('GroomyRoot/components/Buttons/Validation'),
            ErrorFileSize: () => import('GroomyRoot/components/Alert/ErrorAlert')
        },
		data () {
			return {
                document_file: null,
                document_name: '',
                document_date: null,
                document_limit_size: '10', // en Mo
                actual_date: new Date(),
                document_file_multiple: null,
                document_date_multiple: new Date(),
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			init_component() {
                this.document_date = this.actual_date
			},

            async valid_form() {
                if(!this.isValidSizeFile) return

                const document = {
                    document_name: this.document_name,
                    document_date: this.document_date,
                    document_file: this.document_file,
                }

				return this.registerHorseDocument([document])
			},

            async valid_form_multiple() {

                if(!this.isValidSizeFileMultiple) return
                let documents = []
				let i = 1
                
				this.document_file_multiple.forEach(file => {
                    documents.push({
                        document_id: i,
                        document_name: this.document_name + '_' + i,
                        document_date: this.document_date_multiple,
                        document_file: file,
                    })
					i++
                })
                if (this.from_mediatheque == false){
                    return this.$emit('documents', documents)
                }
				return this.ok(documents)
            },
			
			// Android only
			onPermAccepted() {
				this.$refs.fileForm.$el.children[0].click()
			}
		},
        computed: {
            canGoNext() {
                return this.document_name && this.document_date && this.document_file
            },
            canGoNextMultiple() {
                return this.document_date_multiple && this.document_file_multiple
            },
            isValidSizeFile() {
                const file_size = this.document_file.size         // Taille en octets
                const limit = this.document_limit_size * 1000000  // conversion en octets
                return file_size < limit
            },
            isValidSizeFileMultiple() { 
                let isOk = true
                const limit = this.document_limit_size * 1000000 
                this.document_file_multiple.forEach(file => {
                    const file_size = file.size
                    if(file_size >= limit) {
                        isOk = false
                    }
                })
                return isOk
            }
        }
	}
</script>
